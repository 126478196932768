/*
 * @Author: chengyuzhang
 * @Date: 2020-07-03 14:50:41
 * @Last Modified by: zhaoyang
 * @Last Modified time: 2023-02-01 19:43:21
 */

/**
 * 请在 methods 中提供一个 initFetch 方法, 此方法为需要在 server 端调用的接口, 此方法可以接受参数 {header: {cookie, 'User-Agent'}} 用于维持登录状态
 * 接受一个 moduleName 参数, 其为获取 initFetch 返回数据的计算属性, 可以通过 this[moduleName] 来访问到在服务端请求的数据
 * 此 mixin 还提供一个 requestHelper 方法, 用于在 initFetch 中使用, 其作用为在 mounted 后自动展示错误信息, 并赋予默认值
 * 其参数为请求的 Promise 或 一个返回 Promise 的方法
 */
import contentModule from '../store/module/server-prefetch';

export default moduleName => ({
    async serverPrefetch() {
        const {$ssrContext: {cookie, userAgent}} = this;
        const header = {cookie, 'User-Agent': userAgent};

        this.registerModule();
        await this.requestData({header});
        this.$store.commit(`${moduleName}/setLoadFlag`, true);
    },

    mounted() {
        if (!this.serverLoadedFlag) {
            this.registerModule();
            this.requestData();
        } else {
            this.registerModule(true);
        }

        this.handleError();
    },

    async beforeRouteUpdate(to, from, next) {
        await this.requestData({to});
        next();
    },

    computed: {
        [moduleName]() {
            const {$store: {state: {[moduleName]: {responseData = {}} = {}}}} = this;

            return responseData;
        },

        serverLoadedFlag() {
            const {$store: {state: {[moduleName]: {serverLoadedFlag = false} = {}}}} = this;

            return serverLoadedFlag;
        },

        errorInfo() {
            const {$store: {state: {[moduleName]: {error} = {}}}} = this;

            return error;
        }
    },

    methods: {
        registerModule(isClient) {
            this.$store.registerModule(moduleName, contentModule, {preserveState: isClient});
        },

        async requestData(context = {}) {
            this.$store.commit(`${moduleName}/clearError`);
            try {
                const res = await this.initFetch(context);
                this.$store.commit(`${moduleName}/set`, res);
            } catch (err) {
                this.$store.commit(`${moduleName}/set`, {});
            }
        },

        async requestHelper(apiFetch) {
            let apiRes;
            if (typeof apiFetch === 'function') {
                apiRes = apiFetch();
            } else {
                apiRes = apiFetch;
            }

            const res = await apiRes.catch(err => {
                const {config} = err;
                err.data.body = config.defaultValue;
                if (this.$isServer && !config.hideToast) {
                    this.$store.commit(`${moduleName}/setError`, err.data.status);
                }

                return err;
            });

            return res;
        },

        handleError() {
            const {errorInfo} = this;
            const errorMsgMap = {};
            const toast = this.$toast.fail || this.$toast.error;
            if (errorInfo.length === 0) return;
            for (let i = 0; i < errorInfo.length; i += 1) {
                const {detail} = errorInfo[i];
                if (errorMsgMap[detail]) return;
                toast.call(this.$toast, detail);
                errorMsgMap[detail] = true;
            }
        }
    },

    destroyed() {
        this.$store.unregisterModule(moduleName);
    }
});
