/*
 * @Author: chengyuzhang
 * @Date: 2020-07-03 15:21:33
 * @Last Modified by: zhaoyang
 * @Last Modified time: 2023-02-01 19:44:05
 */

export default {
    namespaced: true,

    state: () => ({
        responseData: {},
        serverLoadedFlag: false,
        error: []
    }),

    mutations: {
        set: (state, data) => {
            state.responseData = data;
        },

        setLoadFlag: (state, flag) => {
            state.serverLoadedFlag = flag;
        },

        setError: (state, data) => {
            state.error = [
                ...state.error,
                data
            ];
        },

        clearError: state => {
            state.error = [];
        }
    }
};
